var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.closeLightBox($event)
        }
      }
    },
    [
      _vm.media && _vm.media.length > 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.lightBoxOn,
                  expression: "lightBoxOn"
                }
              ],
              ref: "container",
              staticClass: "vue-lb-container"
            },
            [
              _c("div", { staticClass: "vue-lb-content" }, [
                _c("div", { staticClass: "vue-lb-header" }, [
                  _c("span"),
                  _vm._v(" "),
                  _vm.closable
                    ? _c(
                        "button",
                        {
                          staticClass: "vue-lb-button-close",
                          attrs: { type: "button", title: _vm.closeText }
                        },
                        [_vm._t("close", [_c("CloseIcon")])],
                        2
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "vue-lb-figure",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c(
                      "transition",
                      { attrs: { mode: "out-in", name: "fade" } },
                      [
                        _vm.media[_vm.select].type !== "video"
                          ? _c("img", {
                              key: _vm.media[_vm.select].src,
                              staticClass: "vue-lb-modal-image",
                              attrs: {
                                src: _vm.media[_vm.select].src,
                                srcset: _vm.media[_vm.select].srcset || "",
                                alt: _vm.media[_vm.select].caption
                              }
                            })
                          : _c(
                              "video",
                              {
                                ref: "video",
                                attrs: {
                                  controls: "",
                                  width: _vm.media[_vm.select].width,
                                  height: _vm.media[_vm.select].height,
                                  autoplay: _vm.media[_vm.select].autoplay
                                }
                              },
                              _vm._l(_vm.media[_vm.select].sources, function(
                                source
                              ) {
                                return _c("source", {
                                  key: source.src,
                                  attrs: { src: source.src, type: source.type }
                                })
                              }),
                              0
                            )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._t("customCaption", [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showCaption,
                            expression: "showCaption"
                          }
                        ],
                        staticClass: "vue-lb-info",
                        domProps: {
                          innerHTML: _vm._s(_vm.media[_vm.select].caption)
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "vue-lb-footer" }, [
                      _c("div", { staticClass: "vue-lb-footer-info" }),
                      _vm._v(" "),
                      _vm.media.length > 1
                        ? _c(
                            "div",
                            { staticClass: "vue-lb-footer-count" },
                            [
                              _vm._t(
                                "footer",
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.select + 1) +
                                      " / " +
                                      _vm._s(_vm.media.length) +
                                      "\n            "
                                  )
                                ],
                                {
                                  current: _vm.select + 1,
                                  total: _vm.media.length
                                }
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    ])
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vue-lb-thumbnail-wrapper" }, [
                _vm.showThumbs
                  ? _c(
                      "div",
                      { staticClass: "vue-lb-thumbnail" },
                      [
                        _vm.media.length > 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "vue-lb-thumbnail-arrow vue-lb-thumbnail-left",
                                attrs: {
                                  type: "button",
                                  title: _vm.previousThumbText
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.previousImage()
                                  }
                                }
                              },
                              [_vm._t("previousThumb", [_c("LeftArrowIcon")])],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.imagesThumb, function(image, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    index >= _vm.thumbIndex.begin &&
                                    index <= _vm.thumbIndex.end,
                                  expression:
                                    "index >= thumbIndex.begin && index <= thumbIndex.end"
                                },
                                {
                                  name: "lazy",
                                  rawName: "v-lazy:background-image",
                                  value: image.thumb,
                                  expression: "image.thumb",
                                  arg: "background-image"
                                }
                              ],
                              key:
                                typeof image.thumb === "string"
                                  ? "" + image.thumb + index
                                  : index,
                              class:
                                "vue-lb-modal-thumbnail" +
                                (_vm.select === index ? "-active" : ""),
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.showImage(index)
                                }
                              }
                            },
                            [
                              image.type
                                ? _vm._t("videoIcon", [_c("VideoIcon")])
                                : _vm._e()
                            ],
                            2
                          )
                        }),
                        _vm._v(" "),
                        _vm.media.length > 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "vue-lb-thumbnail-arrow vue-lb-thumbnail-right",
                                attrs: {
                                  type: "button",
                                  title: _vm.nextThumbText
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.nextImage()
                                  }
                                }
                              },
                              [_vm._t("nextThumb", [_c("RightArrowIcon")])],
                              2
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.media.length > 1
                ? _c(
                    "button",
                    {
                      staticClass: "vue-lb-arrow vue-lb-left",
                      attrs: { type: "button", title: _vm.previousText },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.previousImage()
                        }
                      }
                    },
                    [_vm._t("previous", [_c("LeftArrowIcon")])],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.media.length > 1
                ? _c(
                    "button",
                    {
                      staticClass: "vue-lb-arrow vue-lb-right",
                      attrs: { type: "button", title: _vm.nextText },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.nextImage()
                        }
                      }
                    },
                    [_vm._t("next", [_c("RightArrowIcon")])],
                    2
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }